<template>
  <BaseModal name="modal_integration_enotas" :title="title.header">
    <b-row class="ml-1 mt-3">
      <p class="title-item-2 mb-0">01.</p>
      <p class="text-description mb-0 ml-3">
        Adicione seu token de integração Enotas
      </p>
    </b-row>
    <b-row class="ml-1 mt-3 pr-3" v-if="api_key">
      <b-form-input
        id="token"
        v-model="tokenIntegration"
        type="text"
      ></b-form-input>
    </b-row>
    <b-row v-else class="Table-body justify-content-center">
      <p class="nao-encontrado">Necessário Gerar o Token nas configurações</p>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" @click="returnModal"> Fechar </BaseButton>
      <BaseButton
        variant="info3"
        class="ml-3"
        @click="onSubmit"
        :disabled="!activeField"
      >
        {{ title.btnSave }}</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../BaseModal";
import TokenService from "@/services/resources/TokenService";
import NoteCenterIntegration from "@/services/resources/NoteCenterIntegration";

const service = TokenService.build();
const noteCenterIntegrationApi = NoteCenterIntegration.build();

export default {
  name: "IntegrationEnotas",
  data() {
    return {
      api_key: null,
      active: false,
      tokenIntegration: "",
      issueIsThis: false,
      isEdit: false,
    };
  },
  comments: {
    BaseModal,
  },
  computed: {
    title: function() {
      if (this.isEdit) {
        return {
          header: "Editar integração",
          btnSave: "Atualizar integração",
        };
      }
      return {
        header: "Adicione a sua integração",
        btnSave: "Concluir integração",
      };
    },
    activeField: function() {
      if (this.tokenIntegration.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    setActive(val) {
      this.active = val;
    },
    onSubmit() {
      if (!this.tokenIntegration) {
        this.$bvToast.toast("Informe um token válido!", {
          variant: "warning",
          title: "Enotas",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return false;
      } else {
        noteCenterIntegrationApi
          .post("", {
            name: "Enotas",
            config_integration: {
              token: this.tokenIntegration,
            },
            token: this.tokenIntegration,
            default: false,
          })
          .then(() => {
            this.messageSuccess(
              "Integração com Enotas concluída com sucesso!",
              "Adicionado"
            );
            this.$bvModal.hide("modal_integration_enotas");
          });
      }
    },
    generateKey() {
      service
        .create()
        .then((response) => {
          this.api_key = response.api_key;
          this.$bvToast.toast("Suas credenciais foram geradas com sucesso!", {
            title: "Credenciais",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    returnModal() {
      this.$bvModal.show("modal_add_integration");
      this.$bvModal.hide("modal_integration_enotas");
    },
    getToken() {
      service.get("").then((response) => {
        this.api_key = response.api_key;
      });
    },
    copyValue(text, type) {
      const el = document.getElementById(type);
      el.select();
      document.execCommand("copy");
      this.messageSuccess("A chave foi copiada com sucesso!", "Copiado!");
      this.active = true;
    },
    changeConfig(value) {
      this.issueIsThis = value;
    },
    messageSuccess(msg, title) {
      this.$bvToast.toast(msg, {
        variant: "success",
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
  created() {
    this.$root.$on("bv::modal::shown", () => {
      this.getToken();
      noteCenterIntegrationApi
        .search({
          url: "/search/name/Enotas",
          name: "Enotas",
        })
        .then((response) => {
          if (response.id) {
            this.active = true;
            this.isEdit = true;
            this.tokenIntegration = JSON.parse(
              response.config_integration
            ).token;
          }
        });
    });
  },
};
</script>

<style scoped>
.title-item {
  color: #2a63ab;
}
.title-item-2 {
  color: #2a63ab;
}
.custom-width-card {
  width: 154px;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858e;
}
</style>
