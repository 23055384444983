var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseModal',{attrs:{"name":"modal_add_integration","title":"Adicione a sua integração"},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('BaseButton',{attrs:{"variant":"secondary_outline"},on:{"click":cancel}},[_vm._v(" Fechar ")])]}}])},[_c('b-row',{staticClass:"ml-1"},[_c('p',{staticClass:"title-item mb-0"},[_vm._v("01.")]),_c('p',{staticClass:"text-description mb-0 ml-3"},[_vm._v(" Antes de tudo, selecione a plataforma que deseja integração com a Voomp. ")])]),_c('div',{staticClass:"d-flex justify-content-center"},_vm._l((_vm.dataServices),function(dataService){return _c('b-row',{key:dataService.id,staticClass:"mt-4"},[(dataService.active && dataService.name === 'SmartNotas')?_c('b-col',{staticClass:"mr-1",attrs:{"cols":"12","md":"3"}},[_c('div',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                title: 'SmartNotas',
                placement: 'top',
              }),expression:"{\n                title: 'SmartNotas',\n                placement: 'top',\n              }"}],staticClass:"mb-2 custom-width-card",attrs:{"img-alt":"Image","img-top":"","tag":"article","body-class":"pl-0 pr-0 size-card-smartnotas"},on:{"click":_vm.openIntegrationSmartNotas}},[_c('b-card-text',{staticClass:"container-logo"},[_c('img',{attrs:{"src":require("@/assets/img/icons/smartNotas.svg"),"alt":""}})])],1)],1)]):_vm._e(),(dataService.active && dataService.name === 'NotaZZ')?_c('b-col',{staticClass:"ml-1",attrs:{"cols":"12","md":"3"}},[_c('div',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                title: 'NotaZZ',
                placement: 'top',
              }),expression:"{\n                title: 'NotaZZ',\n                placement: 'top',\n              }"}],staticClass:"mb-2 custom-width-card",attrs:{"img-alt":"Image","img-top":"","tag":"article","body-class":"pl-0 pr-0"},on:{"click":_vm.openIntegrationNotaZZ}},[_c('b-card-text',{staticClass:"container-logo"},[_c('img',{attrs:{"src":require("@/assets/img/icons/notaZZ.svg"),"alt":""}})])],1)],1)]):_vm._e(),(dataService.active && dataService.name === 'Enotas')?_c('b-col',{staticClass:"ml-2",attrs:{"cols":"12","md":"3"}},[_c('div',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                title: 'Enotas',
                placement: 'top',
              }),expression:"{\n                title: 'Enotas',\n                placement: 'top',\n              }"}],staticClass:"custom-width-card",attrs:{"img-alt":"Image","img-top":"","tag":"article","body-class":"pl-0 pr-0"},on:{"click":_vm.openIntegrationEnotas}},[_c('b-card-text',{staticClass:"container-logo"},[_c('img',{attrs:{"width":"60","src":require("@/assets/img/icons/enotas.png"),"alt":""}})])],1)],1)]):_vm._e()],1)}),1)],1),_c('IntegrationSmartNotas'),_c('IntegrationNotaZZ'),_c('IntegrationENotas')],1)}
var staticRenderFns = []

export { render, staticRenderFns }