<template>
  <BaseModal name="notes-filters" size="lg" title="Filtros" @shown="openModal">
    <b-row>
      <b-col cols="12">
        <div class="top-filter">
          <div>
            <p>Os seus filtros ficam aqui.</p>
            <p v-if="contemFilterLocal">
              Sempre salvados os filtros que utilizou para facilitar sua busca
            </p>
          </div>
          <BaseButton
            variant="info3"
            class="link-documentacao"
            id="limpar"
            @click="limparLocal"
            :disabled="!contemFilterLocal"
            >Limpar filtros</BaseButton
          >
        </div>
        <b-form novalidate>
          <b-row>
            <b-col>
              <b-form-group label="Produtos">
                <multiselect
                  v-model="filter.products"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Pesquise o Produto"
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="aux_product_debounce"
                >
                  <span slot="noOptions">Pesquise pelo produto</span>

                  <span slot="noResult"
                    >Oops! Nenhum produto encontrado. Pesquise outro termo</span
                  >
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label="Status" label-for="status">
                <multiselect
                  v-model="filter.status"
                  placeholder="Status "
                  label="name"
                  track-by="id"
                  :options="status_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  ><span slot="noResult"
                    >Nenhum status encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="secondary_outline" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="secondary" @click="onSubmit"> Filtrar </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

export default {
  props: {
    value: {
      type: Number,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      pre_filter: {},
      contemFilterLocal: false,
      search: "",
      loading: false,
      loading_contract: false,
      filter: {
        status: [
          {
            id: "NOT_ISSUED",
            name: "Não Emitido",
          },
          {
            id: "PROCESSING",
            name: "Processando",
          },
          {
            id: "ERROR",
            name: "Erro",
          },
        ],
        products: [],
      },
      products: [],
      contracts: [],
      status_list: [
        {
          id: "NOT_ISSUED",
          name: "Não Emitido",
        },
        {
          id: "ISSUED",
          name: "Emitido",
        },
        {
          id: "ISSUED_EXTERNALLY",
          name: "Emitido Externamente",
        },
        {
          id: "PROCESSING",
          name: "Processando",
        },
        {
          id: "ERROR",
          name: "Erro",
        },
      ],
    };
  },

  methods: {
    // produto
    aux_product_debounce(query) {
      this.loading = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function() {
      this.fetchProducts(this.search);
    }, 500),

    // contrato
    aux_contract_debounce(query) {
      this.loading_contract = true;
      this.search = query;
      this.debounce_contract();
    },
    onSubmit() {
      window.localStorage.notes_filters_aux = JSON.stringify({
        status: this.filter.status,
        products: this.filter.products,
      });
      let data = {
        status: this.filter.status.map((item) => item.id),
      };
      if (this.filter.products.length) {
        data.products = this.filter.products.map((item) => item.id);
      }
      this.$emit("filter", data);
      this.$bvModal.hide("notes-filters");
    },
    fetchProducts(search = null) {
      let data = {
        list: true,
      };
      if (search) data.search = search;
      this.products = [];
      serviceProduct
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            this.products.push({ id: element.id, name: element.name });
          });
        })
        .catch((err) => {
          console.log(err);
          this.products = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    limparLocal() {
      window.localStorage.removeItem("notes_filters_aux");
      window.localStorage.removeItem("sales_filters");
      this.filter = {
        status: [
          {
            id: "NOT_ISSUED",
            name: "Não Emitido",
          },
          {
            id: "PROCESSING",
            name: "Processando",
          },
          {
            id: "ERROR",
            name: "Erro",
          },
        ],
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: [],
      };
      this.products = [];
      this.contracts = [];
      this.verificaLocal();
    },
    verificaLocal() {
      if (window.localStorage.notes_filters_aux) {
        this.contemFilterLocal = true;
      } else {
        this.contemFilterLocal = false;
      }
    },
    openModal() {
      this.verificaLocal();
      if (window.localStorage.notes_filters_aux) {
        this.pre_filter = JSON.parse(window.localStorage.notes_filters_aux);
        this.filter = this.pre_filter;
      }
    },
  },
  created() {
    // this.fetchProducts();
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-filter {
    display: block;
  }
  .top-filter #limpar {
    margin-top: 15px;
  }
}
</style>
