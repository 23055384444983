<template>
  <BaseModal name="modal_legal_person_without_docs" title="">
    <div class="sem-docs">
      <p>Ops, vimos que seus documentos ainda não estão validados.</p>
      <p>
        Para emitir como pessoa jurídica, primeiro é necessário que seus
        documentos tenham sido validados pela equipe da Voomp.
      </p>
      <BaseButton variant="info3" @click="goAccount"
        >Verificar documentos agora</BaseButton
      >
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-3" @click="cancel">
        Fechar
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../BaseModal";

export default {
  name: "LegalPersonValidationDocs",
  comments: {
    BaseModal,
  },
  methods: {
    goAccount() {
      this.$router.push("/minha-conta");
    },
  },
};
</script>

<style scoped></style>
