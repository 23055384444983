<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-sales"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      data-anima="sidebar"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel text-left">
          <b-col>
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <b-row class="mb-3">
                    <b-col cols="6">
                      <p class="label-campo">N da Nota</p>
                      <p class="dados-campo">{{ note.note_id }}</p>
                    </b-col>
                    <b-col cols="6">
                      <p class="label-campo">Status</p>
                      <template>
                        <p
                          class="dados-campo"
                          :class="getStatus(note.note_status).class"
                        >
                          {{ getStatus(note.note_status).text }}
                        </p>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="6 mb-3">
                      <p class="label-campo">Dt. Criação</p>
                      <p class="dados-campo">
                        {{ note.note_created_at | date }}
                      </p>
                    </b-col>
                    <b-col cols="6 mb-3" v-if="note.note_emitted_at">
                      <p class="label-campo">Dt. Emissão</p>
                      <p class="dados-campo">
                        {{ note.note_emitted_at | date }}
                      </p>
                    </b-col>

                    <b-col cols="6 mb-3" v-if="note.note_issued_by_integration">
                      <p class="label-campo">Emitido por</p>
                      <p class="dados-campo">
                        {{ note.note_issued_by_integration }}
                      </p>
                    </b-col>

                    <b-col cols="6 mb-3">
                      <p class="label-campo">Valor</p>
                      <p class="dados-campo">
                        R$ {{ formatMoney(+note.sale_amount) }}
                      </p>
                    </b-col>
                    <b-col cols="12 mb-3" v-if="note.note_link">
                      <p class="label-campo">Link da nota</p>
                      <p class="dados-campo">
                        <a :href="note.note_link" target="_blank"
                          >{{
                            note.note_link.length > maxLength
                              ? note.note_link.substr(0, maxLength) + "..."
                              : note.note_link
                          }}
                        </a>
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div
                v-if="
                  note.note_status === 'NOT_ISSUED' ||
                    note.note_status === 'ERROR'
                "
              >
                <BaseButton
                  variant="secondary_outline"
                  class="btn-emit-note-external w-100"
                  @click="handleClickEmittedExternal"
                >
                  Nota emitida externamente
                </BaseButton>
              </div>
              <template>
                <div class="accordion" role="tablist">
                  <b-card
                    no-body
                    class="mb-2 container-card"
                    v-if="note.note_status === 'ERROR'"
                  >
                    <b-card-header
                      header-tag="header"
                      role="tab"
                      class="header-accordion mb-2"
                    >
                      <button
                        v-b-toggle.accordion-0
                        class="btn-collapse"
                        id="btn-accordion-0"
                      >
                        Erro
                        <img
                          src="@/assets/img/icons/arrow-fill.svg"
                          alt=""
                          class="img-arrow"
                          id="icon-accordion-0"
                        />
                      </button>
                    </b-card-header>
                    <b-collapse
                      id="accordion-0"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="body-collapse">
                        <b-card-text>
                          <b-col class="sumary">
                            <b-col cols="12">
                              <p class="label-campo">Motivo do erro</p>
                              <p class="dados-campo">
                                {{ note.response_error.error }}
                              </p>
                            </b-col>
                            <b-col
                              cols="12"
                              v-if="note.response_error.integration"
                            >
                              <p class="label-campo">
                                Integração que retornou o erro
                              </p>
                              <p class="dados-campo">
                                {{ note.response_error.integration }}
                              </p>
                            </b-col>
                          </b-col>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>

                  <b-card no-body class="mb-2 container-card">
                    <b-card-header
                      header-tag="header"
                      role="tab"
                      class="header-accordion mb-2"
                    >
                      <button
                        v-b-toggle.accordion-1
                        class="btn-collapse"
                        id="btn-accordion-1"
                      >
                        Origem
                        <img
                          src="@/assets/img/icons/arrow-fill.svg"
                          alt=""
                          class="img-arrow"
                          id="icon-accordion-1"
                        />
                      </button>
                    </b-card-header>
                    <b-collapse
                      id="accordion-1"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="body-collapse">
                        <b-card-text>
                          <b-col class="sumary">
                            <b-row class="mb-3">
                              <b-col cols="12">
                                <p class="label-campo">Razão social</p>
                                <p class="dados-campo">
                                  {{ note.saller_name }}
                                </p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3">
                              <b-col cols="12">
                                <p class="label-campo">CPF/CNPJ</p>
                                <p class="dados-campo">
                                  {{ note.saller_cpf_cnpj }}
                                </p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3">
                              <b-col cols="12 mb-12">
                                <p class="label-campo">E-mail</p>
                                <p class="dados-campo">
                                  {{ note.saller_email }}
                                </p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3" v-if="note.saller_street">
                              <b-col cols="12 mb-12">
                                <p class="label-campo">Endereço</p>
                                <p class="dados-campo">
                                  {{ getAddress("SELLER") }}
                                </p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3">
                              <b-col cols="12 mb-12">
                                <p class="label-campo">Telefone</p>
                                <p class="dados-campo">
                                  {{ note.client_cellphone }}
                                </p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  <b-card no-body class="mb-2 container-card">
                    <b-card-header
                      header-tag="header"
                      role="tab"
                      class="header-accordion mb-2"
                    >
                      <button v-b-toggle.accordion-2 class="btn-collapse">
                        Destino
                        <img
                          src="@/assets/img/icons/arrow-fill.svg"
                          alt=""
                          class="img-arrow"
                          id="icon-accordion-2"
                        />
                      </button>
                    </b-card-header>
                    <b-collapse
                      id="accordion-2"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="body-collapse">
                        <b-card-text>
                          <b-col class="sumary">
                            <b-row class="mb-3">
                              <b-col cols="12">
                                <p class="label-campo">Razão social</p>
                                <p class="dados-campo">
                                  {{ note.client_name }}
                                </p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3">
                              <b-col cols="12">
                                <p class="label-campo">CPF/CNPJ</p>
                                <p class="dados-campo">
                                  {{ note.client_cpf_cnpj }}
                                </p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3">
                              <b-col cols="12 mb-12">
                                <p class="label-campo">E-mail</p>
                                <p class="dados-campo">
                                  {{ note.client_email }}
                                </p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3" v-if="note.client_street">
                              <b-col cols="12 mb-12">
                                <p class="label-campo">Endereço</p>
                                <p class="dados-campo">{{ getAddress() }}</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3">
                              <b-col cols="12 mb-12">
                                <p class="label-campo">Telefone</p>
                                <p class="dados-campo">
                                  {{ note.client_cellphone }}
                                </p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  <b-card no-body class="mb-2 container-card">
                    <b-card-header
                      header-tag="header"
                      role="tab"
                      class="header-accordion mb-2"
                    >
                      <button v-b-toggle.accordion-3 class="btn-collapse">
                        Detalhes
                        <img
                          src="@/assets/img/icons/arrow-fill.svg"
                          alt=""
                          class="img-arrow"
                          id="icon-accordion-3"
                        />
                      </button>
                    </b-card-header>
                    <b-collapse
                      id="accordion-3"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="body-collapse">
                        <b-card-text>
                          <b-col class="sumary">
                            <b-row class="mb-3">
                              <b-col cols="12 mb-3">
                                <p class="label-campo">ID Produto</p>
                                <p class="dados-campo">{{ note.product_id }}</p>
                              </b-col>
                              <b-col cols="12">
                                <p class="label-campo">Produto</p>
                                <p class="dados-campo">
                                  {{ note.product_name }}
                                </p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3">
                              <b-col cols="12">
                                <p class="label-campo">Valor</p>
                                <template>
                                  <p class="dados-campo">
                                    R$ {{ formatMoney(+note.sale_amount) }}
                                  </p>
                                </template>
                              </b-col>
                            </b-row>

                            <b-row class="mb-3" v-if="categoryFiscal">
                              <b-col cols="12">
                                <p class="label-campo">Categoria fiscal</p>
                                <template>
                                  <p class="dados-campo">
                                    {{ categoryFiscal }}
                                  </p>
                                </template>
                              </b-col>
                            </b-row>

                            <b-row class="mb-3">
                              <b-col cols="12 mb-6 mb-3">
                                <p class="label-campo">Cliente</p>
                                <p class="dados-campo">
                                  {{ note.client_name }}
                                </p>
                              </b-col>
                              <b-col cols="12 mb-6">
                                <p class="label-campo">Telefone</p>
                                <p class="dados-campo">
                                  {{ note.client_cellphone }}
                                </p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </template>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>
<script>
import Money from "@/mixins/money";
import NoteCenter from "@/services/resources/NoteCenter";
import moment from "moment-timezone";
import Vue from "vue";
const apiNoteCenter = NoteCenter.build();

export default {
  name: "NoteCenterPreview",
  mixins: [Money],
  data() {
    return {
      loading: true,
      visible: false,
      note_id: -1,
      note: { response_error: { error: "", integration: "" } },
    };
  },
  computed: {
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
    categoryFiscal() {
      if (this.note.note_status === "ISSUED") {
        if (this.note.note_category_fiscal === "PRODUCT") {
          return "Produto";
        } else if (this.note.note_category_fiscal === "SERVICE") {
          return "Serviço";
        }
      }
      if (this.note.product_category_fiscal === "PRODUCT") {
        return "Produto";
      } else if (this.note.product_category_fiscal === "SERVICE") {
        return "Serviço";
      }
      return "";
    },
    getAddress() {
      return (type) => {
        if (type === "SELLER") {
          return `${this.note.saller_street}, ${this.note.saller_number} - ${this.note.saller_city} - ${this.note.saller_state}`;
        }
        return `${this.note.client_street}, ${this.note.client_number} - ${this.note.client_city} - ${this.note.client_uf}`;
      };
    },
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      const icon = document.getElementById("icon-" + collapseId);
      if (icon) {
        if (isJustShown) {
          icon.classList.add("img-arrow-rotate");
        } else {
          icon.classList.remove("img-arrow-rotate");
        }
      }
    });
  },
  methods: {
    handleClickEmittedExternal() {
      Vue.swal({
        title: `Emitida externamente?`,
        text: `Realmente deseja marcar nota como emitida externamente?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Emitida externamente",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.externalIssue();
        }
      });
    },
    externalIssue() {
      this.loading = true;
      apiNoteCenter
        .post("update/" + this.note_id, {
          status: "ISSUED_EXTERNALLY",
          emitted_at: moment().format("YYYY-MM-DD"),
        })
        .then(() => {
          this.$bvToast.toast("Nota marcada como emitida com sucesso!", {
            title: "Nota",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.fetchNote();
          this.$emit("externalIssue", this.note_id);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status) {
          case "ISSUED": {
            r = { text: "Emitido", class: "icon-green" };
            break;
          }
          case "NOT_ISSUED": {
            r = { text: "Não Emitido", class: "icon-warning" };
            break;
          }
          case "ISSUED_EXTERNALLY": {
            r = { text: "Emitido Externamente", class: "icon-blue" };
            break;
          }
          case "ERROR": {
            r = { text: "Erro", class: "icon-red" };
            break;
          }
          case "PROCESSING": {
            r = { text: "Processando", class: "icon-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "icon-black" };
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
    },
    openPanel(note_id) {
      this.visible = true;
      this.note_id = note_id;
      this.fetchNote();
    },
    fetchNote() {
      this.loading = true;
      apiNoteCenter
        .read("/preview/" + this.note_id)
        .then((response) => {
          this.note = response;
          if (this.note.response_error) {
            this.note.response_error = JSON.parse(this.note.response_error);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-emit-note-external {
  margin-bottom: 30px;
  width: 92%;
  height: 50px;
  color: #fff;
  background: #2a63ab;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.label-campo {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 8px;
}

.container-card {
  background-color: #f7f8f9;
  border: 0;
}

.img-arrow {
  width: 10px;
  height: 5.83px;
  border-radius: 1px;
}

.img-arrow-rotate {
  transform: rotate(-180deg);
}

.body-collapse {
  background: #f7f7f7;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
}

.btn-collapse {
  height: 57px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-align: left;
  background: #f7f8f9;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.btn-collapse:active {
  background: #e5e5e5 !important;
  color: #000000 !important;
}

.header-accordion {
  border-radius: 5px;
  background: #f7f8f9;
  border: 0;
}

.panel {
  .sumary {
    font-size: 13px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 600;
    display: block;
  }
  .product-name span {
    display: block;
    margin: 10px 0 !important;
  }
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}
.detalhe-item {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.detalhe-item + .detalhe-item {
  margin-top: 25px;
}
header.b-sidebar-header {
  padding: 30px !important;
  padding-bottom: 0 !important;
}
</style>
