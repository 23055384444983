<template>
  <div class="container">
    <BaseHeader title="Central de Notas"> </BaseHeader>
    <div class="container-table">
      <div class="box-total">
        <p class="total-vendas info">⚡️ {{ itens }} Itens</p>
        <div>
          <p class="total-vendas">
            Total
            <span>R$ {{ total ? formatMoney(total) : "0,00" }}</span>
          </p>
        </div>
        <div></div>
      </div>
      <div class="header-table">
        <div class="flex-sale">
          <div class="d-flex align-items-center mr-4">
            <b-tooltip
              target="filtro-1"
              title="Aplicar Filtro"
              placement="topright"
            />
            <b-button
              id="filtro-1"
              class="btn-table"
              @click="openFilters"
              title="Filtros"
              ><img src="@/assets/img/icons/filtro.svg"
            /></b-button>
            <b-button
              class="btn-table"
              title="Filtros"
              @click="openModalConfiguration"
              ><img src="@/assets/img/icons/config.svg" width="20"
            /></b-button>
          </div>

          <div class="d-flex align-items-center flex-acoes">
            <div class="datas">
              <date-range-picker
                ref="picker"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                @update="fetchNotes"
                v-model="dateRange"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template
                  v-else
                  v-slot:input
                  class="btn-table"
                  title="Selecionar datas de pagamento"
                >
                  Selecione as datas de pagamento
                </template>
              </date-range-picker>
            </div>
          </div>
        </div>
        <b-col cols="12" md="4" class="container-pesquisa mt-2">
          <div class="inputSearch">
            <img src="@/assets/img/icons/search.svg" class="Pointer search" />
            <input
              type="text"
              placeholder="cliente"
              v-on:input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>

        <b-col class="text-right d-none d-md-block">
          <button @click="openModalAddIntegration" class="btn-add-integration">
            Adicionar Integração
          </button>
        </b-col>
      </div>
      <!-- buttons -->
      <b-row
        v-if="item_check.length > 0"
        class="btn-action-items-selected-animation mt-3"
      >
        <b-button
          v-b-tooltip="{
            title:
              allItensChecked === false
                ? 'Marcar' + ` todos os  ${total_items_to_select}  itens`
                : 'Desmarcar' + ` todos os  ${total_items_to_select}  itens`,
            placement: 'bottom',
          }"
          variant="outline-primary"
          style="background: rgba(33, 51, 210, 0.1) !important"
          class="btn-table"
          @click="itemsToSelect"
        >
          <img src="@/assets/img/icons/checkItem.svg" alt="" />
        </b-button>

        <b-button
          v-b-tooltip="{ title: 'Emitida externamente', placement: 'bottom' }"
          style="background: rgba(237, 237, 240, 0.5) !important"
          class="btn-table"
          @click="handleClickEmittedExternal"
        >
          <img src="@/assets/img/icons/twoRow.svg" alt="" />
        </b-button>

        <b-button
          v-b-tooltip="{ title: 'Emitir', placement: 'bottom' }"
          class="btn-table"
          style="background: rgba(237, 237, 240, 0.5) !important"
          @click="emitirManual"
        >
          <img src="@/assets/img/icons/extra-modal.svg" alt="" />
        </b-button>

        <b-button
          @click="exportNote"
          v-b-tooltip="{
            title: 'Exportar',
            placement: 'bottom',
          }"
          class="btn-table ml-3"
        >
          <img
            src="@/assets/img/icons/download.svg"
            alt=""
            class="d-inline-block"
          />
        </b-button>
      </b-row>
      <!-- HEADER -->
      <b-row class="Table-header mb-1">
        <b-col cols="0" class="d-none d-md-block pr-3 pl-3">
          <b-form-checkbox-group
            id="checkbox-group-all"
            class="input-check"
            name="flavour-all"
            v-model="item_check2"
            @change="clickCheckAll"
          >
            <b-form-checkbox value="xx"></b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
        <b-col cols="1" class="d-none d-md-block mr-2 ml-2"> Nota </b-col>
        <b-col cols="5" md="2" class=""> Dt. Criação </b-col>
        <b-col cols="4" md="2"> Produto </b-col>
        <b-col cols="3" class="d-none d-md-block">Cliente </b-col>
        <b-col cols="2" class="d-none d-md-block">Valor </b-col>
        <b-col cols="1" md="1" class="text-center"> Visualizar </b-col>
      </b-row>

      <!-- BODY -->
      <template v-if="!loading && notes.length > 0">
        <b-row class="Table-body" v-for="item in notes" :key="item.id">
          <b-col cols="0" class="d-none d-md-block pl-3 pb-2 mr-4">
            <b-form-checkbox
              :value="item.id"
              :disabled="
                item.fiscal_centers_status !== 'NOT_ISSUED' &&
                  item.fiscal_centers_status !== 'ERROR'
              "
              :id="`checkbox-group-` + item.id"
              :name="`flavour-` + item.id"
              @change="clickCheck"
              v-model="item_check"
            ></b-form-checkbox>
          </b-col>
          <b-col
            cols="1"
            class="d-none d-md-block status-item ml-2"
            :class="getStatus(item.fiscal_centers_status).class"
            v-b-tooltip="{
              title: getStatus(item.fiscal_centers_status).text,
              placement: 'bottom',
            }"
          >
            <span>{{ item.id }}</span>
          </b-col>
          <template>
            <b-col cols="5" md="2" class="">
              {{ item.fiscal_centers_created_at | datetime }}
            </b-col>
          </template>
          <template>
            <b-col
              cols="4"
              md="2"
              class=""
              v-b-tooltip="{
                title: item.product,
                placement: 'top',
              }"
            >
              {{
                item.product.length > maxLength
                  ? item.product.substr(0, maxLength) + "..."
                  : item.product
              }}
            </b-col>
          </template>

          <b-col
            cols="3"
            class="d-none d-md-block dados-cliente"
            v-b-tooltip="{
              title: item.name,
              placement: 'top',
            }"
          >
            {{
              item.name.length > maxLength
                ? item.name.substr(0, maxLength) + "..."
                : item.name
            }}
          </b-col>

          <b-col cols="2" class="d-none d-md-block">
            R$ {{ formatMoney(+item.amount) }}
          </b-col>

          <b-col cols="2" md="1">
            <div class="Table-body-action" @click="openPanel(item.id)">
              <img src="@/assets/img/icons/eye.svg" />
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <Paginate
            v-if="notes.length > 0 && !initExport"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <div
        v-if="(loading && !notes.length) || (loading && initExport)"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && !notes.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma nota foi encontrada</p>
      </b-row>

      <FiltersModal @filter="filter" />
      <NoteCenterPreview
        ref="noteCenterPreview"
        @externalIssue="externalIssueByPreview"
      />
      <Configuration
        :integrations="integrations"
        :idIntegrationToDefault="idIntegrationToDefault"
        :integrationDefault="integrationDefault"
        :nameIntegrationsSelected="nameIntegrationsSelected"
        :user="user"
        :dataConfigurationOfPaidAt="dataConfigurationOfPaidAt"
        @selectIntegration="openModalIntegrations"
        @changePersonIssuer="changePersonIssuer"
      />
      <AddIntegration />
      <ListIntegration
        :integrations="integrations"
        @integrationSelected="integrationSelected"
        :modalToReturn="modalToReturn"
        :user="user"
        :dataConfigurationOfPaidAt="dataConfigurationOfPaidAt"
      />
    </div>
    <!--<CallEvaluation /> -->
  </div>
</template>
<script>
import Paginate from "@/components/shared/Paginate";
import FiltersModal from "@/components/Seller/NoteCenter/modals/Filters";
import Configuration from "@/components/Seller/NoteCenter/modals/Configuration";
import DateRangePicker from "vue2-daterange-picker";
import Money from "@/mixins/money";
import moment from "moment-timezone";
import _ from "lodash";
import NoteCenter from "@/services/resources/NoteCenter";
import NoteCenterPreview from "@/components/Seller/NoteCenter/NoteCenterPreview.vue";
import Vue from "vue";
import AddIntegration from "@/components/Seller/NoteCenter/modals/AddIntegration";
import ListIntegration from "@/components/Seller/NoteCenter/modals/ListIntegration";
import NoteCenterIntegration from "@/services/resources/NoteCenterIntegration";
const noteCenterIntegrationApi = NoteCenterIntegration.build();
const apiNoteCenter = NoteCenter.build();

export default {
  name: "ListagemVendas",
  components: {
    Paginate,
    FiltersModal,
    DateRangePicker,
    NoteCenterPreview,
    Configuration,
    AddIntegration,
    ListIntegration,
  },
  data() {
    return {
      idIntegrationToDefault: null,
      integrationDefault: {},
      integrations: {},
      modalToReturn: "",
      initExport: false,
      notes: [],
      note_id: -1,
      search: "",
      loading: true,
      total: 0,
      itens: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      filters: {},
      type_date: "created_at",
      types_list: [
        { value: "created_at", text: "Data de criação" },
        { value: "paid_at", text: "Data de pagamento" },
      ],
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      client: {
        width: 0,
      },
      item_check: [],
      item_check2: [],
      selected_action: [],
      activeBtnExternallyIssued: true,
      total_items_to_select: 0,
      allItensChecked: false,
      emitManual: false,
      nameIntegrationsSelected: "",
      user: {
        documents: [],
        company: {
          documents: [],
        },
      },
      dataConfigurationOfPaidAt: {},
      selectedAll: false,
      sendEmit: false,
    };
  },
  mixins: [Money],
  computed: {
    active() {
      return this.$route.name;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2020, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },
  methods: {
    changePersonIssuer(change) {
      this.dataConfigurationOfPaidAt.issue_by_legal_entity = change;
    },
    configurationOfPaidAt() {
      apiNoteCenter
        .get("configuration-paid")
        .then((response) => {
          this.dataConfigurationOfPaidAt = response;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    emitirManual() {
      this.configurationOfPaidAt();
      this.$bvModal.show("modal_integrations");
      this.getIntegrations();
      this.emitManual = true;
    },
    integrationSelected(integration) {
      if (this.emitManual) {
        let auxText = this.item_check.length > 1 ? "s" : "";
        Vue.swal({
          title: `Emissão de nota manual`,
          text: `Deseja realmente emitir o total de ${this.item_check.length} nota${auxText} pela ${integration.name}`,
          type: "danger",
          showCancelButton: true,
          confirmButtonText: `Emitir nota${auxText}`,
          cancelButtonText: "Cancelar",
          customClass: "sweet-container",
          confirmButtonClass: "button button-black mt-3 mb-3",
          cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
          buttonsStyling: false,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let itemCheck = this.item_check;
            this.item_check = [];
            this.item_check2 = [];

            if (this.selectedAll) {
              this.sendEmit = true;
            } else {
              this.sendEmit = false;
            }

            this.notes.forEach((item, index) => {
              if (itemCheck.find((id) => id == item.id)) {
                this.notes[index].fiscal_centers_status = "PROCESSING";
              }
            });

            this.messageSuccess(
              `Iniciamos a emissão do total de ${itemCheck.length}
             nota` +
                auxText +
                ` na sua integração com ` +
                integration.name,
              "Emissão de nota" + auxText
            );

            apiNoteCenter
              .post("manual-emit", {
                notes: itemCheck,
                integration_id: integration.id,
              })
              .then((d) => {})
              .catch((e) => console.log(e));

            setTimeout(() => {
              this.getFiltersSalved();
            }, 1000);

            this.loading = false;
          } else {
            this.emitirManual();
          }
        });
      } else {
        this.integrationDefault = integration;
        this.idIntegrationToDefault = integration.id;
        this.nameIntegrationsSelected = integration.name;
      }
    },
    messageSuccess(msg, title) {
      this.$bvToast.toast(msg, {
        variant: "info",
        title: title,
        autoHideDelay: 8000,
        appendToast: true,
      });
    },
    openModalIntegrations() {
      this.$bvModal.show("modal_integrations");
      this.$bvModal.hide("modal_configuration");
      this.modalToReturn = "modal_configuration";
    },
    getIntegrations() {
      this.emitManual = false;
      this.modalToReturn = "";
      this.integrationDefault = {};
      this.idIntegrationToDefault = null;
      noteCenterIntegrationApi.get("").then((response) => {
        this.integrations = response;
        response.forEach((item) => {
          if (item.default) {
            this.integrationDefault = item;
            this.idIntegrationToDefault = item.id;
          }
        });
      });
    },
    openModalAddIntegration() {
      this.$bvModal.show("modal_add_integration");
    },
    handleClickEmittedExternal() {
      let auxString = this.item_check.length > 1 ? "s" : "";
      Vue.swal({
        title: `Emitida${auxString} externamente?`,
        text: `Deseja marcar nota${auxString} como emitida${auxString} externamente?. Total de ${this.item_check.length} nota${auxString} `,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: `Emitida${auxString} externamente`,
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.externalIssue();
        }
      });
    },
    openModalConfiguration() {
      this.getIntegrations();
      this.$bvModal.show("modal_configuration");
      this.nameIntegrationsSelected = "";
    },
    exportNote() {
      this.$bvToast.toast("Iniciamos a exportação dos dados, aguarde", {
        variant: "info",
        title: "Exportação",
        autoHideDelay: 8000,
        appendToast: true,
      });
      apiNoteCenter
        .search({ url: "export", note: this.item_check })
        .then((response) => {
          let linkSource =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            response.data;
          let downloadLink = document.createElement("a");
          let date = new Date();
          let fileName = "fiscal_note_" + date.getTime() + ".xlsx";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.download();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    itemsToSelect() {
      if (this.allItensChecked) {
        this.allItensChecked = false;
        this.item_check2 = [];
        this.selectedAll = false;
        return (this.item_check = []);
      }
      this.selectedAll = true;
      let data = {};
      data.client = this.search;
      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data = Object.assign(data, this.filters);
      if (this.dateRange.startDate && this.dateRange.endDate) {
        data.date_start = moment
          .tz(this.dateRange.startDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment
          .tz(this.dateRange.endDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }
      apiNoteCenter
        .search({
          url: "/items-to-select",
          ...data,
        })
        .then((response) => {
          this.allItensChecked = true;
          this.item_check = response;
          this.item_check2 = ["xx"];
          this.$bvToast.toast(
            `Foi selecionado o total de ${this.item_check.length} itens`,
            {
              title: "Notas",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    externalIssueByPreview(noteId) {
      this.getFiltersSalved();
      this.item_check = this.item_check.map((item) => {
        if (item != noteId) {
          return item;
        }
      });
    },
    externalIssue() {
      this.loading = true;
      apiNoteCenter
        .post("externally-issued", { notes: this.item_check })
        .then(() => {
          this.fetchNotes();
          this.$bvToast.toast(
            `${this.item_check.length} Nota${
              this.item_check.length > 1 ? "s" : ""
            }
        marcada como emitida externamente efetuada com sucesso!`,
            {
              title: "Nota",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          document.querySelector("body").removeAttribute("style");
          this.item_check = [];
          this.item_check2 = [];
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clickCheckAll(event) {
      if (event.length === 0) {
        this.item_check = [];
        this.item_check2 = [];
        return;
      }
      let notes = this.notes;
      let array = [];
      for (let i = 0; i < notes.length; i++) {
        if (notes[i].fiscal_centers_status === "NOT_ISSUED") {
          const element = notes[i].id;
          array.push(element);
        }
      }
      this.item_check = array;
    },
    clickCheck() {
      this.item_check2 = [];
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status) {
          case "ISSUED": {
            r = { text: "Emitido", class: "is-green" };
            break;
          }
          case "NOT_ISSUED": {
            r = { text: "Não Emitido", class: "is-warning" };
            break;
          }
          case "ISSUED_EXTERNALLY": {
            r = { text: "Emitido Externamente", class: "is-light-blue" };
            break;
          }
          case "ERROR": {
            r = { text: "Erro na emissão da nota", class: "is-red" };
            break;
          }
          case "PROCESSING": {
            r = { text: "Processando", class: "is-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "is-black" };
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    changedType() {
      this.fetchNotes();
    },
    debounce: _.debounce(function() {
      this.pagination.currentPage = 1;
      this.search = this.search.replace("+", "");
      this.filters.client = this.search;
      this.fetchNotes();
    }, 500),
    openPanel(note_id) {
      this.note_id = note_id;
      this.$router.push({ query: { s_id: this.note_id } }).catch(() => {});
      setTimeout(() => {
        this.$refs.noteCenterPreview.openPanel(this.note_id);
        this.loading = false;
      });
    },
    openFilters() {
      this.$bvModal.show("notes-filters");
    },
    exportData() {
      this.fetchNotes();
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchNotes();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchNotes();
    },
    filter(filters) {
      this.pagination.currentPage = 1;
      this.filters = filters;
      this.fetchNotes();
    },
    fetchNotes(dados_local = null) {
      this.loading = true;
      let data = {};

      if (dados_local) {
        data = dados_local;
        this.search = data.client ?? "";
      } else {
        data = {
          client: this.search ?? "",
        };
        let notesFiltersAux = localStorage.getItem("notes_filters_aux");
        if (!notesFiltersAux) {
          data.status = ["NOT_ISSUED", "PROCESSING", "ERROR"];
        }
      }
      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );

      data = Object.assign(data, this.filters);
      if (this.dateRange.startDate && this.dateRange.endDate) {
        data.date_start = moment
          .tz(this.dateRange.startDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment
          .tz(this.dateRange.endDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      apiNoteCenter
        .search({
          ...data,
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
        })
        .then((response) => {
          this.saveSearch(data);
          this.notes = response.data.map((note) => {
            if (this.selectedAll && this.sendEmit) {
              if (note.fiscal_centers_status === "NOT_ISSUED") {
                note.fiscal_centers_status = "PROCESSING";
              }
            }
            return note;
          });
          this.pagination.totalPages = response.last_page;
          this.total = response.total_amount;
          this.itens = response.total;
          this.total_items_to_select = response.total_items_to_select;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    download() {
      this.$bvToast.toast("Dados exportados com sucesso", {
        variant: "success",
        title: "Exportação",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    searchByFilter(dados_filter) {
      this.dateRange.startDate = this.decodeDateFilter(dados_filter.date_start);
      this.dateRange.endDate = this.decodeDateFilter(dados_filter.date_end);
      this.fetchNotes(dados_filter);
    },
    decodeDateFilter(date) {
      switch (date) {
        // hoje
        case "hoje": {
          return moment();
        }
        // ontem
        case "ontem": {
          return moment().subtract(1, "days");
        }
        // uma semana atras
        case "uma semana": {
          return moment().subtract(7, "days");
        }
        // primeiro dia do mes atual
        case "primeiro dia mes atual": {
          return moment().startOf("month");
        }
        // ultimo dia do mes atual
        case "ultimo dia mes atual": {
          return moment().endOf("month");
        }
        // primeiro dia do ANO atual
        case "primeiro dia ano": {
          return moment().startOf("year");
        }
        // ultimo dia do ANO atual
        case "ultimo dia ano": {
          return moment().endOf("year");
        }

        // primeiro dia do mes passado
        case "primeiro dia mes passado": {
          return moment()
            .startOf("month")
            .subtract(1, "month");
        }
        // ultimo dia do mes passado
        case "ultimo dia mes passado":
          return moment()
            .endOf("month")
            .subtract(1, "month")
            .add(1, "days");
        default: {
          return date;
        }
      }
    },
    saveSearch(dados_pesquisa) {
      window.localStorage.notes_filters = {};
      dados_pesquisa.date_start = this.formatDateFilter(
        dados_pesquisa.date_start
      );
      dados_pesquisa.date_end = this.formatDateFilter(dados_pesquisa.date_end);
      window.localStorage.notes_filters = JSON.stringify(dados_pesquisa);
    },
    formatDateFilter(date) {
      switch (date) {
        // hoje
        case moment().format("YYYY-MM-DD"): {
          return "hoje";
        }
        // ontem
        case moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"): {
          return "ontem";
        }
        // uma semana atras
        case moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"): {
          return "uma semana";
        }
        // primeiro dia do mes atual
        case moment()
          .startOf("month")
          .format("YYYY-MM-DD"): {
          return "primeiro dia mes atual";
        }
        // ultimo dia do mes atual
        case moment()
          .endOf("month")
          .format("YYYY-MM-DD"): {
          return "ultimo dia mes atual";
        }
        // primeiro dia do ANO atual
        case moment()
          .startOf("year")
          .format("YYYY-MM-DD"): {
          return "primeiro dia ano";
        }
        // ultimo dia do ANO atual
        case moment()
          .endOf("year")
          .format("YYYY-MM-DD"): {
          return "ultimo dia ano";
        }

        // primeiro dia do mes passado
        case moment()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"): {
          return "primeiro dia mes passado";
        }
        // ultimo dia do mes passado
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"):
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .add(1, "days")
          .format("YYYY-MM-DD"): {
          return "ultimo dia mes passado";
        }
        default: {
          return date;
        }
      }
    },
    getFiltersSalved() {
      // filtros salvos
      if (window.localStorage.notes_filters) {
        this.filters = JSON.parse(window.localStorage.notes_filters);
        this.searchByFilter(this.filters);
      } else {
        this.fetchNotes();
      }
    },
  },
  created() {
    this.user = this.$store.state.user.user;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$store.getters.setPanel) {
      this.$router.push("/dashboard");
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loading = true;
    this.configurationOfPaidAt();
    this.getFiltersSalved();
    if (typeof this.$route.query.s_id != "undefined") {
      this.note_id = this.$route.query.s_id;
      setTimeout(() => {
        this.$refs.noteCenterPreview.openPanel(this.note_id);
        this.loading = false;
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.status-item:before {
  left: -14px !important;
  top: 8px !important;
}

.btn-add-integration {
  width: 229px;
  height: 50px;
  color: #fff;
  background: #2a63ab;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.btn-action-items-selected-animation {
  animation: animationactionsbtn linear 0.5s !important;
  animation-iteration-count: 1 !important;
  transform-origin: 50% 50% !important;
  -webkit-animation: animationactionsbtn linear 0.5s !important;
  -webkit-animation-iteration-count: 1 !important;
  -webkit-transform-origin: 50% 50% !important;
  -moz-animation: animationactionsbtn linear 0.5s !important;
  -moz-animation-iteration-count: 1 !important;
  -moz-transform-origin: 50% 50% !important;
  -o-animation: animationactionsbtn linear 0.5s !important;
  -o-animation-iteration-count: 1 !important;
  -o-transform-origin: 50% 50% !important;
  -ms-animation: animationactionsbtn linear 0.5s !important;
  -ms-animation-iteration-count: 1 !important;
  -ms-transform-origin: 50% 50% !important;
}

@keyframes animationactionsbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes animationactionsbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes animationactionsbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes animationactionsbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes animationactionsbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 33, 99, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 33, 99, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 315px;
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 52px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2),
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}
.whats-cliente {
  font-size: 13px;
  color: #002363;
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}
</style>
